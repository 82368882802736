<template>
  <div>
    <!--搜索-->
    <el-row>
      <el-col :span="24">
        <div class="search">
          <el-form :inline="true" :model="queryInfo">
            <el-form-item class="text" label="用户姓名">
              <el-input
                  placeholder="请输入用户姓名"
                  v-model="queryInfo.name"
                  size="small"
                  clearable>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button icon="el-icon-search" size="mini" type="primary" @click="refresh()">搜索</el-button>
              <el-button icon="el-icon-refresh" size="mini" @click="onReset">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
    </el-row>
    <!--工具栏-->
    <el-row>
      <el-col :span="24">
        <div class="tools">
          <el-button @click="handleAdd" size="mini" icon="el-icon-plus" type="primary">新增</el-button>
          <el-button :loading="false" :disabled="false" size="mini" icon="el-icon-refresh" @click="refresh()">刷新
          </el-button>
        </div>
      </el-col>
    </el-row>
    <!--表格-->
    <el-row>
      <el-col :span="24">
        <div style="width: auto;">
          <el-table
              :data="tableData"
              stripe
              style="width: 100%;">
            <el-table-column
                type="selection"
                align="center"
                width="110">
            </el-table-column>
            <el-table-column
                prop="id"
                label="用户编号"
                width="250">
            </el-table-column>
            <el-table-column
                prop="name"
                label="用户姓名"
                width="250">
            </el-table-column>
              <el-table-column
                      prop="mobilePhone"
                      label="用户手机号"
                      width="250">
              </el-table-column>
              <el-table-column
                      prop="createTime"
                      label="创建时间">
              </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                    size="mini"
                    type="text"

                    icon="el-icon-edit"
                    @click="handleEdit(scope.$index, scope.row)">编辑
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div style="margin-top: 15px;text-align: right;">
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryInfo.page"
                :page-sizes="[10,20]"
                :page-size="10"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>
          </div>
        </div>
      </el-col>
    </el-row>



    <el-dialog
        title="新增"
        :visible.sync="addDialogVisible"
        width="50%">
      <el-form :inline="true" :model="addUserInfo">
        <el-form-item class="text" label="用户手机号（也是登录账号）">
          <el-input
              placeholder="请输入用户手机号"
              v-model="addUserInfo.mobilePhone"
              size="small"
              clearable>
          </el-input>
        </el-form-item>
        <el-form-item class="text" label="用户名称">
          <el-input
              placeholder="请输入用户名称"
              v-model="addUserInfo.name"
              size="small"
              clearable>
          </el-input>
        </el-form-item>
          <el-form-item class="text" label="用户密码">
              <el-input
                      placeholder="请输入用户密码"
                      v-model="addUserInfo.password"
                      size="small"
                      clearable>
              </el-input>
          </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="addDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="addUser()">确 定</el-button>
  </span>
    </el-dialog>

    <el-dialog
        title="编辑"
        :visible.sync="editDialogVisible"
        width="50%">
      <el-form :inline="true" :model="editUserInfo">
        <el-form-item class="text" label="用户手机号（也是登录账号）">
          <el-input
              placeholder="请输入用户手机号"
              v-model="editUserInfo.mobilePhone"
              size="small"
              clearable>
          </el-input>
        </el-form-item>
        <el-form-item class="text" label="用户名称">
          <el-input
              placeholder="请输入用户名称"
              v-model="editUserInfo.name"
              size="small"
              clearable>
          </el-input>
        </el-form-item>
          <el-form-item class="text" label="用户密码">
              <el-input
                placeholder="请输入用户密码"
                v-model="editUserInfo.password"
                size="small"
                clearable>
              </el-input>
          </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="editDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="editUser()">确 定</el-button>
  </span>
    </el-dialog>

  </div>
</template>

<script>
import { queryUserPage } from '@/api/user.js'
import { addUser } from '@/api/user.js'
import { delUser } from '@/api/user.js'
import { editUserRequest } from '@/api/user.js'

export default {
  name: "Manager",
  data() {
    return {
      addUserInfo: {
          mobilePhone: "",
          name: "",
          password: ""
      },
      editUserInfo: {
        mobilePhone: "",
        name: "",
        password: "",
        id: "",
        version: ""
      },
      tableData: [],
      queryInfo: {
        name: "",
        //当前的页数
        page: 1,
        //当前每页显示的多少条数据
        pageSize: 10,
        type: 1
      },
      total: 0,
      addDialogVisible: false,
      editDialogVisible: false
    }
  },
  created () {
      this.refresh();
  },
  methods: {
    onSubmit() {
      console.log('submit!');
    },
    onReset() {
      this.queryInfo.name = "";
      this.refresh();
    },
    handleAdd(){
      this.addDialogVisible=true;
    },
    refresh() {
      queryUserPage(this.queryInfo).then((res) => {
          this.tableData = res.data.records
          this.total = res.data.total
          this.queryInfo.page = res.data.current
          this.queryInfo.pageSize = res.data.size
      })
    },
    addUser() {
        addUser(this.addUserInfo).then((res) => {
            if (res.code === 0) {
              this.$message.success("添加成功")
                // 添加成功
                this.refresh();
            } else {
                // 添加失败
                this.$message.error(res.msg)
            }
            this.addDialogVisible = false
        })
        
    },
    editUser() {
        const params = {
         mobilePhone: undefined,
         name: undefined,
         id: this.editUserInfo.id,
         version: this.editUserInfo.version,
         password: undefined
       }
        if (this.editUserInfo.mobilePhone != "") {
          params.mobilePhone = this.editUserInfo.mobilePhone
          this.editUserInfo.mobilePhone = ""
        }
        if (this.editUserInfo.name != "") {
          params.name = this.editUserInfo.name
          this.editUserInfo.name = ""
        }
        if (this.editUserInfo.password != "") {
          params.password = this.editUserInfo.password
          this.editUserInfo.password = ""
        }
       
       editUserRequest(params).then((res) => {
         if (res.code == 0) {
           this.$message.success("修改成功")
           this.refresh();
         } else {
           this.$message.error(res.msg)
           
         }
       })
       this.editDialogVisible = false
    },
    handleEdit(index, row) {
      this.editUserInfo.id = row.id
      this.editUserInfo.version = row.version
      this.editDialogVisible=true;
    },
    handleDelete(index, row) {
      console.log(index, row);
      this.$confirm('是否确认删除用户编号为"' + row.id + '"的数据项??', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        delUser(row.id).then((res) => {
          if (res.code === 0) {
            this.$message.success("删除成功!")
          } else {
            this.$message.error(row.msg)
          }
        })

        this.refresh();
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.refresh()
    },
    handleCurrentChange(val) {
      this.queryInfo.page = val
      this.refresh()
    },
  }
}
</script>
<!--公共样式-->
<style scoped lang="scss">

</style>
